import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { useChatContext } from "../../Context/ChatContext";
import { UserContext } from "../../../../Context/UserContext";
// import Skeleton from "react-loading-skeleton";
// import MyProductDetailsLoading from "./MyProductDetailsLoading/MyProductDetailsLoading";
import { PulseLoader } from "react-spinners";
import { useQueryClientContext } from "../../../../Context/QueryClientContext";
import { toast } from "react-toastify";

function AdminProductDetails() {
  const { id } = useParams();

  const [product, setProduct] = useState(null);

  const {
    baseUrl,
    avatar,
    userData,
    addToWishList,
    removeFromWishList,
    setUserData,
    setToken,
    profile,
    token,
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [stopIsLoading, setStopIsLoading] = useState(false);
  const [blockIsLoading, setBlockIsLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const queryClient = useQueryClientContext();
  const { pathname } = useLocation();
  console.log(pathname);

  const navigate = useNavigate();

  const getProduct = async () => {
    setIsLoading(true);

    const url = pathname?.includes("productDetails")
      ? `${baseUrl}/product/${id}/productForAdmin`
      : `${baseUrl}/product/${id}/productRequestById`;

    const data = await axios
      .get(url, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        if (!err.response) {
          toast.error("Check the internet and reload the site");

        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    console.log(data);

    setProduct(data?.data?.product && data.data.product);
    setIsLoading(false);
  };

  const acceptProduct = async (id) => {
    console.log(id);

    setAcceptLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/product/${id}/accept`,
        {},
        {
          headers: {
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        setAcceptLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");

        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data.message == "Done") {
      setAcceptLoading(false);
       queryClient.invalidateQueries("fetchInitialProducts");
       queryClient.invalidateQueries("getAllProductsNames");
      navigate("/dashboard/productRequests");
    }
  };
  const rejectProduct = async (id) => {
    setRejectLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/product/${id}/refused`,
        {},
        {
          headers: {
            authorization: `Hamada__${token}`,
          },
        }
      )
      .catch((err) => {
        setRejectLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");

        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.message == "Done") {
      setRejectLoading(false);
      navigate("/dashboard/productRequests");
    }
  };
  const blockProduct = async () => {
    setBlockIsLoading(true);
    const data = await axios
      .patch(
        `${baseUrl}/product/${id}/block`,
        {},
        {
          headers: { authorization: `Hamada__${token}` },
        }
      )
      .catch((err) => {
        console.log(err);

        setBlockIsLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");

        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    setBlockIsLoading(false);
    if (data?.data?.message == "Done") {
      setProduct(data.data.product);
       queryClient.invalidateQueries("fetchInitialProducts");
       queryClient.invalidateQueries("getAllProductsNames");
    }
  };

  useEffect(() => {
    getProduct();
  }, []);
  
  return (
    <div className="text-black p-5">
      <h3 className=" font-semibold text-lg lg:text-xl">Product details</h3>
      {isLoading || !product ? (
        // <MyProductDetailsLoading />
        <></>
      ) : (
        <div className="grid grid-cols-12 gap-4 p-5">
          <div className="col-span-full lg:col-span-6">
            <label className=" font-medium">Name in Arabic</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2  py-2 px-4 whitespace-pre-wrap break-words bg-white">
              {product.arName}
            </p>
          </div>
          <div className="col-span-full lg:col-span-6">
            <label className="font-medium">Name in English</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
              {product.enName}
            </p>
          </div>
          <div className="col-span-full lg:col-span-6">
            <label className=" font-medium">Description in Arabic</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
              {product.arDescription}
            </p>
          </div>
          <div className="col-span-full lg:col-span-6">
            <label className=" font-medium">Description in English</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
              {product.enDescription}
            </p>
          </div>
          <div className=" col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Price</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
              {product.price}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Discound</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
              {product.discound || 0}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Stock</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
              {product.stock}
            </p>
          </div>
          <div className=" col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Model</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
              {product.model}
            </p>
          </div>
          <div className="col-span-full md:col-span-6 lg:col-span-4">
            <label className=" font-medium">Status</label>
            <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
              {product.status}
            </p>
          </div>
          {product.status == "Used" ? (
            <div className="col-span-full md:col-span-6 lg:col-span-4">
              <label className=" font-medium">Hours</label>
              <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
                {product.time}
              </p>
            </div>
          ) : (
            <div className="hidden md:block md:col-span-6 lg:col-span-4">
            </div>
          )}

          <div className="grid grid-cols-12 gap-4 col-span-full">
            <div className="col-span-full md:col-span-6 lg:col-span-4">
              <label className=" font-medium">Category Name</label>
              <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
                {product.categoryId.enName}
              </p>
            </div>
            <div className="col-span-full md:col-span-6 lg:col-span-4">
              <label className=" font-medium">Subcategory Name</label>
              <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
                {product.subcategoryId.enName}
              </p>
            </div>
            <div className="col-span-full md:col-span-6 lg:col-span-4">
              <label className=" font-medium">Brand Name</label>
              <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
                {product.brandId.enName}
              </p>
            </div>
            <div className="col-span-full md:col-span-6 lg:col-span-4">
              <label className=" font-medium">Trader Name</label>
              <p className="mt-1 font-medium text-sm text-textbody border-2 py-2 px-4 whitespace-pre-wrap break-words bg-white ">
                {product.createdBy.userName}
              </p>
            </div>
          </div>

          <div className="col-span-full sm:col-span-6 lg:col-span-3">
            <label className=" font-medium">Main image</label>
            <img
              className="mt-1 rounded-md w-full"
              src={product?.mainImage.secure_url}
              alt=""
            />
          </div>
          {product?.subImages?.length ? (
            <div className="col-span-full">
              <label className=" font-medium">Image list</label>
              <div className="mt-1 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-10 gap-4">
                {product.subImages.map((image) => {
                  return (
                    <div key={image._id} className="col-span-2">
                      <img
                        className="rounded-md w-full"
                        src={image.secure_url}
                        alt=""
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="col-span-full">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-full md:col-span-6 lg:col-span-4">
                <label className=" font-medium">Category Status</label>
                <p
                  className={`mt-1 font-medium bg-white text-sm  ${
                    product.categoryDeleted ? "text-red-400" : "text-textbody"
                  }  border-2 py-2 px-4 whitespace-pre-wrap break-words `}
                >
                  {product.categoryDeleted ? "Not active" : "Active"}
                </p>
              </div>
              <div className="col-span-full md:col-span-6 lg:col-span-4">
                <label className=" font-medium">Subcategory Status</label>
                <p
                  className={`mt-1 font-medium text-sm bg-white  ${
                    product.subcategoryDeleted
                      ? "text-red-400"
                      : "text-textbody"
                  }  border-2 py-2 px-4 whitespace-pre-wrap break-words `}
                >
                  {product.subcategoryDeleted ? "Not active" : "Active"}
                </p>
              </div>
              <div className="col-span-full md:col-span-6 lg:col-span-4">
                <label className=" font-medium">Brand Status</label>
                <p
                  className={`mt-1 font-medium text-sm bg-white  ${
                    product.brandDeleted ? "text-red-400" : "text-textbody"
                  }  border-2 py-2 px-4 whitespace-pre-wrap break-words `}
                >
                  {product.brandDeleted ? "Not active" : "Active"}
                </p>
              </div>
              <div className="col-span-full md:col-span-6 lg:col-span-4">
                <label className=" font-medium">Product Status</label>
                <p
                  className={`mt-1 font-medium text-sm bg-white ${
                    (!product.accepted && !product.refused) ||
                    product.blocked ||
                    product.deleted ||
                    product.categoryDeleted ||
                    product.subcategoryDeleted ||
                    product.brandDeleted ||
                    product.traderDeleted
                      ? "text-red-400"
                      : "text-textbody"
                  } border-2 py-2 px-4 whitespace-pre-wrap break-words `}
                >
                  {!product.accepted && !product.refused
                    ? "Not accepted"
                    : product.blocked
                    ? "Blocked"
                    : product.deleted
                    ? "Stopped"
                    : product.categoryDeleted
                    ? "Not active"
                    : product.subcategoryDeleted
                    ? "Not active"
                    : product.brandDeleted
                    ? "Not active"
                    : product.traderDeleted
                    ? "Not active"
                    : "Active"}
                </p>
              </div>
              <div className="col-span-full md:col-span-6 lg:col-span-4">
                <label className=" font-medium">Trader Status</label>
                <p
                  className={`mt-1 font-medium text-sm bg-white ${
                    product.traderDeleted ? "text-red-400" : "text-textbody"
                  }  border-2 py-2 px-4 whitespace-pre-wrap break-words `}
                >
                  {product.traderDeleted ? "Not active" : "Active"}
                </p>
              </div>
            </div>
          </div>
          {pathname.includes("productDetails") ? (
            <div className="col-span-full md:col-span-6 lg:col-span-4">
              {blockIsLoading ? (
                <button
                  disabled
                  className={`py-2 px-4 outline-none text-white ${
                    product.blocked ? "bg-main" : "bg-red-500"
                  }   rounded-md w-full`}
                >
                  <PulseLoader
                    color="#fff"
                    cssOverride={{}}
                    loading
                    margin={2}
                    size={7}
                    speedMultiplier={1}
                  />
                </button>
              ) : (
                <button
                  onClick={() => blockProduct()}
                  className={`py-2 px-4 outline-none text-white ${
                    product.blocked ? "bg-main" : "bg-red-500"
                  }  rounded-md w-full`}
                >
                  {product.blocked ? "Active product" : "Block product"}
                </button>
              )}
            </div>
          ) : (
            <div className="col-span-full grid grid-cols-12 gap-4">
              <div className="col-span-full md:col-span-6 lg:col-span-4">
                {acceptLoading ? (
                  <button
                    disabled
                    className="py-2 px-4 w-full outline-none rounded-lg text-white bg-main"
                  >
                    <PulseLoader
                      color="#fff"
                      cssOverride={{}}
                      loading
                      margin={2}
                      size={7}
                      speedMultiplier={1}
                    />
                  </button>
                ) : (
                  <button
                    disabled={isLoading}
                    onClick={() => acceptProduct(product?._id)}
                    className="py-2 px-4 w-full outline-none rounded-lg text-white bg-main"
                  >
                    Accept
                  </button>
                )}
              </div>
              <div className="col-span-full md:col-span-6 lg:col-span-4">
                {rejectLoading ? (
                  <button
                    disabled
                    className="py-2 px-4 w-full outline-none rounded-md bg-profileColor"
                  >
                    <PulseLoader
                      color="#000"
                      cssOverride={{}}
                      loading
                      margin={2}
                      size={7}
                      speedMultiplier={1}
                    />
                  </button>
                ) : (
                  <button
                    disabled={isLoading}
                    onClick={() => rejectProduct(product._id)}
                    className="py-2 px-4 w-full outline-none rounded-md bg-profileColor"
                  >
                    Reject
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AdminProductDetails;
