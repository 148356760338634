import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
// import { Link } from "react-router-dom";
// import PreviewImage from "../../PreviewImage/PreviewImage";
// import { useFormik } from "formik";
import * as Yup from "yup";
import PreviewImage from "../../PreviewImage/PreviewImage";
import { BsCloudUpload } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { UserContext } from "../../../Context/UserContext";
import PreviewImages from "../../PreviewImage/PreviewImages";
import { PulseLoader } from "react-spinners";
import Skeleton from "react-loading-skeleton";
import { useQueryClientContext } from "../../../Context/QueryClientContext";

function AddProduct() {
  const { baseUrl, setUserData, setToken, token } = useContext(UserContext);
  const [isOpenedCategory, setIsOpenedCategory] = useState(false);
  const [isOpenedSubcategory, setIsOpenedSubcategory] = useState(false);
  const [isOpenedBrand, setIsOpenedBrand] = useState(false);
  const [categoryValue, setCategoryValue] = useState(null);
  const [categoryError, setCategoryError] = useState(null);
  const [subcategoryValue, setSubcategoryValue] = useState(null);
  const [subcategoryError, setSubcategoryError] = useState(null);
  const [brandValue, setBrandValue] = useState(null);
  const [brandError, setBrandError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [subImagesErr, setSubImagesErr] = useState(null);
  const [err, setErr] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [subImages, setSubImages] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState("New");
  const [statusError, setStatusError] = useState(null);
  const [timeError, setTimeError] = useState(null);
  const [isOpenedStatus, setIsOpenedStatus] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClientContext();
  const getAllCategories = async () => {
    setCategoriesLoading(true);
    const data = await axios
      .get(`${baseUrl}/category?fields=enName`)
      .catch((err) => {
        setCategoriesLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        }
      });
    if (data?.data?.categories) {
      setCategories(data.data.categories);
      setCategoriesLoading(false);
    }
  };

  const getAllBrands = async () => {
    setBrandsLoading(true);
    const data = await axios
      .get(`${baseUrl}/brand?fields=enName`)
      .catch((err) => {
        setBrandsLoading(false);
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        }
      });
    if (data?.data?.brands) {
      setBrands(data.data.brands);
      setBrandsLoading(false);
    }
  };

  useEffect(() => {
    getAllCategories();
    getAllBrands();
  }, []);

  const initialValues = {
    arName: "",
    enName: "",
    arDescription: "",
    enDescription: "",
    stock: "",
    price: "",
    discound: "",
    model: "",
    time: "",
    mainImage: null,
  };

  const currentYear = new Date().getFullYear();

  const validationSchema = Yup.object({
    arName: Yup.string()
      .matches(
        /^[\u0621-\u064A0-9 ]+$/,
        "You have to use just Arabic letters and numbers"
      )
      .required("User name is required")
      .min(2, "the minlengt of user name  is 2")
      .max(50, "the maxlength of user name is 20"),
    enName: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]+$/,
        "You have to use just English letters and numbers"
      )
      .required("User name is required")
      .min(2, "the minlengt of user name  is 2")
      .max(50, "the maxlength of user name is 20"),
    arDescription: Yup.string()
      .matches(
        /^[\u0621-\u064A0-9 ]+$/,
        "You have to use just Arabic letters and numbers"
      )
      .required("arDescription is required")
      .min(3, "the minlengt of user Arabic description  is 2"),
    enDescription: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]+$/,
        "You have to use just English letters and numbers"
      )
      .required("enDescription is required")
      .min(3, "the minlengt of user Arabic description  is 2"),
    price: Yup.number()
      .min(1, "the min of price is 1")
      .required("price is required"),
    discound: Yup.number()
      .min(0, "the min of discound is 0")
      .max(100, "the max of discound is 100"),
    stock: Yup.number()
      .min(1, "the min of discound is 1")
      .required("stock is required"),
    time: Yup.number()
      .min(1, "The min number of hours is 1")
      .max(30000, "The max number of hours is 30000"),
    model: Yup.number()
      .min(2000, "min year is 2000")
      .max(currentYear, `max year is ${currentYear}`)
      .required("Model is required"),
    mainImage: Yup.mixed()
      .required("Main image is required")
      .test(
        "FILE_SIZE",
        "Main image is too big",
        (value) => value && value.size < 1024 * 1024
      )
      .test(
        "FILE_TYPE",
        "In-valid image",
        (value) =>
          value && ["image/png", "image/jpeg", "image/jpg"].includes(value.type)
      ),
  });

  function validateImageFormat(image) {
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validTypes.includes(image.type)) {
      return false;
    }
    return true;
  }

  async function addProduct(values) {
    setIsLoading(true);
    if (brandError || categoryError || subcategoryError) {
      setIsLoading(false);
      return 0;
    }
    const {
      arName,
      enName,
      arDescription,
      enDescription,
      stock,
      price,
      discound,
      mainImage,
      model,
      time,
    } = values;
    if (!mainImage) {
      setIsLoading(false);
      return 0;
    }

    if (subImages?.length > 5) {
      setSubImagesErr("the limit of Image list is 5 images");
      setIsLoading(false);
      return 0;
    }

    if (status == "Used" && !time) {
      setTimeError("You have to write how many time did you use");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("arName", arName);
    formData.append("enName", enName);
    arDescription.trim() && formData.append("arDescription", arDescription);
    enDescription.trim() && formData.append("enDescription", enDescription);
    formData.append("stock", stock);
    formData.append("categoryId", categoryValue.categoryId);
    formData.append("subcategoryId", subcategoryValue.subcategoryId);
    formData.append("brandId", brandValue.brandId);
    formData.append("price", price);
    formData.append("model", model);
    formData.append("status", status);
    status == "Used" && formData.append("time", time);
    discound && formData.append("discound", discound);
    formData.append("mainImage", mainImage);
    if (subImages?.length > 0) {
      for (let i = 0; i < subImages.length; i++) {
        const image = subImages[i].file["0"];

        if (!validateImageFormat(image)) {
          setSubImagesErr(
            "Invalid image format. Only JPG, JPEG, and PNG are allowed."
          );
          setIsLoading(false);
          return 0;
        }
      }

      // Append images to FormData if validation passes
      subImages.forEach((image, index) => {
        formData.append(`subImages`, image.file["0"]);
      });
    }

    const data = await axios
      .post(`${baseUrl}/product`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Hamada__${token}`,
        },
      })
      .catch((err) => {
        setIsLoading(false);

        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        } else {
          setErr(err.response.data.errMass);
        }
      });
    if (data?.data?.message == "Done") {
      setIsLoading(false);
      queryClient.invalidateQueries("fetchInitialProducts");
      queryClient.invalidateQueries("getAllProductsNames");
      toast.success("Product added successfully");
      setErr(null);
      values.arName = "";
      values.enName = "";
      values.arDescription = "";
      values.enDescription = "";
      values.stock = "";
      values.discound = "";
      values.price = "";
      values.mainImage = null;
      setSubImages([]);
      setCategoryValue(null);
      setSubcategoryValue(null);
      setBrandValue(null);
    }
  }
  const handleKeyDown = (event, fieldName) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default behavior of adding a newline
      const currentValue = formik.values[fieldName];
      const updatedValue = currentValue + " "; // Add a space instead of a newline
      formik.setFieldValue(fieldName, updatedValue); // Update the formik value
    }
  };

  const handleSubmit = () => {
    if (!categoryValue?.categoryId) {
      setCategoryError("You've to choose a specific category");
    }
    if (!subcategoryValue?.subcategoryId) {
      setSubcategoryError("You've to choose a specific subcategory");
    }
    if (!brandValue?.brandId) {
      setBrandError("You've to choose a specific brand");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("mainImage", file);
  };

  const handleSubImageChange = (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) return;

    console.log(files);

    if (subImages.length < 5) {
      setSubImagesErr(null);
      setSubImages([...subImages, { file: files, id: count + 1 }]);
      setCount(count + 1);

      console.log(subImages);
    } else {
      console.log(subImages);
      setSubImagesErr("The max number of Image list is 5");
    }
    // formik.setFieldValue("subImages", [...files]);
  };

  const remaoveFromSubImages = (id) => {
    const finalImage = [];
    for (const image of subImages) {
      if (image.id != id) {
        finalImage.push(image);
      }
    }

    setSubImages(finalImage);
    setSubImagesErr(null);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: addProduct,
  });

  return (
    <div>
      <h3 className="text-black font-semibold text-lg lg:text-xl">
        Create Product
      </h3>

      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-12 gap-5 mt-5"
      >
        <div className="col-span-full lg:col-span-6">
          <label className="font-medium text-black" htmlFor="arName">
            Name in Arabic
          </label>
          <input
            type="text"
            name="arName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.arName}
            id="arName"
            className={`${
              formik.errors.arName && formik.touched.arName ? "" : "mb-2"
            } w-full text-lg mt-1 outline-none border-2 focus:border-main py-1 px-2`}
            placeholder="Name in Arabic"
          />
          {formik.errors.arName && formik.touched.arName ? (
            <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {formik.errors.arName}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-span-full lg:col-span-6">
          <label className="font-medium text-black" htmlFor="enName">
            Name in English
          </label>
          <input
            type="text"
            name="enName"
            id="enName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.enName}
            className={`${
              formik.errors.enName && formik.touched.enName ? "" : "mb-2"
            } w-full text-lg mt-1 outline-none border-2 focus:border-main py-1 px-2`}
            placeholder="Name in English"
          />
          {formik.errors.enName && formik.touched.enName ? (
            <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {formik.errors.enName}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-span-full lg:col-span-6">
          <label className="font-medium text-black" htmlFor="arDescription">
            Description in Arabic
          </label>
          <textarea
            type="text"
            name="arDescription"
            id="arDescription"
            onKeyDown={(e) => handleKeyDown(e, "arDescription")}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.arDescription}
            rows={4}
            className={` ${
              formik.errors.arDescription && formik.touched.arDescription
                ? ""
                : "mb-2"
            }  w-full text-lg mt-1 outline-none resize-none border-2 focus:border-main py-1 px-2`}
            placeholder="Description in Arabic"
          ></textarea>
          {formik.errors.arDescription && formik.touched.arDescription ? (
            <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {formik.errors.arDescription}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-span-full lg:col-span-6">
          <label className="font-medium text-black" htmlFor="enDescription">
            Description in English
          </label>
          <textarea
            type="text"
            name="enDescription"
            id="enDescription"
            onKeyDown={(e) => handleKeyDown(e, "enDescription")}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.enDescription}
            rows={4}
            className={` ${
              formik.errors.enDescription && formik.touched.enDescription
                ? ""
                : "mb-2"
            }  w-full text-lg mt-1 outline-none resize-none border-2 focus:border-main py-1 px-2`}
            placeholder="Description in English"
          ></textarea>
          {formik.errors.enDescription && formik.touched.enDescription ? (
            <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {formik.errors.enDescription}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-span-full lg:col-span-4">
          <label className="font-medium text-black" htmlFor="price">
            Price
          </label>
          <input
            type="number"
            name="price"
            id="price"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.price}
            className="w-full text-lg mt-1 outline-none border-2 focus:border-main py-1 px-2"
            placeholder="Price"
          />
          {formik.errors.price && formik.touched.price ? (
            <div
              className={`${
                formik.errors.price && formik.touched.price ? "" : "mb-2"
              }  mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full`}
            >
              {formik.errors.price}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-span-full lg:col-span-4">
          <label className="font-medium text-black" htmlFor="discound">
            Discound %
          </label>
          <input
            type="number"
            name="discound"
            id="discound"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={`${formik.values.discound}`}
            className={` ${
              formik.errors.discound && formik.touched.discound ? "" : "mb-2"
            }  w-full text-lg mt-1 outline-none border-2 focus:border-main py-1 px-2`}
            placeholder="Discound"
          />
          {formik.errors.discound && formik.touched.discound ? (
            <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {formik.errors.discound}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-span-full lg:col-span-4">
          <label className="font-medium text-black" htmlFor="stock">
            Stock
          </label>
          <input
            type="number"
            name="stock"
            id="stock"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.stock}
            className={`${
              formik.errors.stock && formik.touched.stock ? "" : "mb-2"
            }  w-full text-lg mt-1 outline-none border-2 focus:border-main py-1 px-2`}
            placeholder="Stock"
          />
          {formik.errors.stock && formik.touched.stock ? (
            <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {formik.errors.stock}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-span-full grid grid-cols-12 gap-5">
          <div className="col-span-full lg:col-span-4">
            <label className="font-medium text-black" htmlFor="model">
              Model
            </label>
            <input
              type="text"
              name="model"
              id="model"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.model}
              className="w-full text-lg mt-1 outline-none border-2 focus:border-main py-1 px-2"
              placeholder="Model"
            />
            {formik.errors.model && formik.touched.model ? (
              <div
                className={`${
                  formik.errors.model && formik.touched.model ? "" : "mb-2"
                } rounded-md text-red-400 text-lg font-medium px-3 w-full`}
              >
                {formik.errors.model}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="col-span-full lg:col-span-4">
            <label className="font-medium text-black" htmlFor="arDescription">
              Status
            </label>
            <label
              onClick={() => setIsOpenedStatus((prev) => !prev)}
              htmlFor="category"
              className={`px-2 py-1 mt-1 pr-[15%] block text-lg select-none w-full border-2 outline-none focus:border-main ${
                isOpenedStatus ? "border-main" : ""
              }  duration-300 relative text-black text-start `}
            >
              {status}

              {isOpenedStatus ? (
                <AiOutlineCaretUp className="text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]" />
              ) : (
                <AiOutlineCaretDown
                  className={`text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]`}
                />
              )}

              <div
                className={`${
                  isOpenedStatus ? "block" : "hidden"
                } absolute p-2 border-2 bg-white z-30 left-0 top-full right-0 border-main text-center`}
              >
                <div className="flex gap-1 flex-col">
                  <div
                    onClick={() => {
                      setStatus("New");
                      setStatusError(null);
                    }}
                    className="py-1 rounded-md cursor-pointer text-main  hover:text-white hover:bg-main font-medium duration-300"
                  >
                    New
                  </div>
                  <div
                    onClick={() => {
                      setStatus("Used");
                      setStatusError(null);
                    }}
                    className="py-1 rounded-md cursor-pointer text-main  hover:text-white hover:bg-main font-medium duration-300"
                  >
                    Used
                  </div>
                </div>
              </div>
            </label>

            {statusError ? (
              <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                {statusError}
              </div>
            ) : (
              ""
            )}
          </div>
          {status == "Used" ? (
            <div className="col-span-full lg:col-span-4">
              <label className="font-medium text-black" htmlFor="time">
                Hours
              </label>
              <input
                type="number"
                name="time"
                id="time"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.time}
                className={`${
                  formik.errors.time && formik.touched.time ? "" : "mb-2"
                }  w-full text-lg mt-1 outline-none border-2 focus:border-main py-1 px-2`}
                placeholder="How many used"
              />
              {formik.errors.time && formik.touched.time ? (
                <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                  {formik.errors.time}
                </div>
              ) : (
                ""
              )}
              {timeError ? (
                <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
                  {timeError}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="col-span-full lg:col-span-4"></div>
          )}
        </div>

        <div className="col-span-full lg:col-span-4">
          <label
            onClick={() => setIsOpenedCategory(!isOpenedCategory)}
            htmlFor="category"
            className={`p-2 pr-[15%] block text-lg select-none w-full border-2 outline-none focus:border-main ${
              isOpenedCategory ? "border-main" : ""
            }  duration-300 relative ${
              categoryValue?.categoryId ? "text-black" : "text-gray-400"
            } text-start `}
          >
            {!categoryValue?.categoryId ? "Category" : categoryValue.name}
            {isOpenedCategory ? (
              <AiOutlineCaretUp className="text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]" />
            ) : (
              <AiOutlineCaretDown
                className={`text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]`}
              />
            )}

            <div
              className={`${
                isOpenedCategory ? "block" : "hidden"
              } absolute max-h-[20vh] p-2  overflow-y-auto scrollbar-thin scrollbar-webkit flex gap-1 flex-col border-2 bg-white left-0 top-full right-0 border-main text-center`}
            >
              {categoriesLoading ? (
                <Skeleton className="h-7 w-full rounded-md" count={3} />
              ) : (
                categories.map((category) => (
                  <div
                    onClick={() => {
                      setCategoryValue({
                        categoryId: category._id,
                        name: category.enName,
                      });
                      setCategoryError(null);
                      setSubcategoryValue(null);
                    }}
                    key={category._id}
                    className="py-1 rounded-md cursor-pointer text-main  hover:text-white hover:bg-main font-medium duration-300"
                  >
                    {category.enName}
                  </div>
                ))
              )}
            </div>
          </label>

          {categoryError ? (
            <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {categoryError}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-span-full lg:col-span-4">
          <label
            onClick={() => setIsOpenedSubcategory(!isOpenedSubcategory)}
            className={`p-2 pr-[15%] block text-lg select-none w-full border-2 outline-none focus:border-main ${
              isOpenedSubcategory ? "border-main" : ""
            }  duration-300 relative ${
              subcategoryValue?.subcategoryId ? "text-black" : "text-gray-400"
            } text-start `}
          >
            {!subcategoryValue?.subcategoryId
              ? "Subcategory"
              : subcategoryValue.name}

            {isOpenedSubcategory ? (
              <AiOutlineCaretUp className="text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]" />
            ) : (
              <AiOutlineCaretDown
                className={`text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]`}
              />
            )}

            <div
              className={`${
                isOpenedSubcategory ? "block" : "hidden"
              } absolute max-h-[20vh] p-2  overflow-y-auto scrollbar-thin scrollbar-webkit  flex flex-col gap-1 border-2 bg-white left-0 top-full right-0 border-main text-center`}
            >
              {categoryValue?.categoryId &&
              categories.find((obj) => obj._id === categoryValue.categoryId)
                ?.subcategory?.length ? (
                categories
                  .find((obj) => obj._id === categoryValue.categoryId)
                  .subcategory.map((subcategory) => (
                    <div
                      key={subcategory._id}
                      onClick={() => {
                        setSubcategoryValue({
                          subcategoryId: subcategory._id,
                          name: subcategory.enName,
                        });
                        setSubcategoryError(null);
                      }}
                      className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                    >
                      {subcategory.enName}
                    </div>
                  ))
              ) : (
                <p>
                  {!categoryValue?.categoryId
                    ? "You've to choose a specific category"
                    : "There is no subcategories in this category"}
                </p>
              )}
            </div>
          </label>
          {subcategoryError ? (
            <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {subcategoryError}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-span-full lg:col-span-4">
          <label
            onClick={() => setIsOpenedBrand(!isOpenedBrand)}
            className={`p-2 pr-[15%] block text-lg select-none w-full border-2 outline-none focus:border-main ${
              isOpenedBrand ? "border-main" : ""
            }  duration-300 relative ${
              brandValue?.brandId ? "text-black" : "text-gray-400"
            } text-start `}
          >
            {!brandValue?.brandId ? "Brand" : brandValue.name}
            {isOpenedBrand ? (
              <AiOutlineCaretUp className="text-lg absolute top-1/2 text-main -translate-y-1/2 right-[5%]" />
            ) : (
              <AiOutlineCaretDown
                className={`text-lg absolute top-1/2 text-black -translate-y-1/2 right-[5%]`}
              />
            )}

            <div
              className={`${
                isOpenedBrand ? "block" : "hidden"
              } absolute max-h-[20vh] p-2  overflow-y-auto scrollbar-thin scrollbar-webkit  flex flex-col gap-1 border-2 bg-white left-0 top-full right-0 border-main text-center`}
            >
              {brandsLoading ? (
                <Skeleton className="h-5 w-full rounded-md" count={2} />
              ) : (
                brands.map((brand) => (
                  <div
                    key={brand.enName}
                    onClick={() => {
                      setBrandValue({
                        brandId: brand._id,
                        name: brand.enName,
                      });
                      setBrandError(null);
                    }}
                    className="py-1 rounded-md cursor-pointer text-main hover:text-white hover:bg-main font-medium duration-300"
                  >
                    {brand.enName}
                  </div>
                ))
              )}
            </div>
          </label>
          {brandError ? (
            <div className=" mb-2 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {brandError}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-span-full">
          <label
            htmlFor="mainImage"
            className="font-medium text-sm md:text-base"
          >
            Main image
          </label>
          <input
            className="hidden"
            id="mainImage"
            name="mainImage"
            type="file"
            onChange={handleFileChange}
            accept="image/*"
          />

          <label
            className={`mt-1 ${
              formik.errors.mainImage && formik.touched.mainImage ? "" : "mb-3"
            } bg-white  font-medium border-2 focus:border-main cursor-pointer pt-2 pb-10 px-4 flex items-center gap-3 w-full`}
            htmlFor="mainImage"
          >
            attach a file <BsCloudUpload />
            {formik.values.mainImage?.name && (
              <div className="w-20">
                <PreviewImage mainImage={formik.values.mainImage} />
              </div>
            )}
          </label>

          {formik.errors.mainImage && formik.touched.mainImage ? (
            <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {formik.errors.mainImage}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-span-full">
          <label className="font-medium text-sm md:text-base">Image list</label>

          <input
            className="hidden"
            id="subImages"
            name="subImages"
            type="file"
            onChange={handleSubImageChange}
            accept="image/*"
          />
          <div
            className={`flex flex-col lg:flex-row lg:items-center mt-1 gap-3 ${
              err || subImagesErr ? "mb-3" : ""
            }`}
          >
            <div className="w-full lg:w-3/4 bg-white border-2 rounded-md focus:border-main font-medium p-4 flex items-center gap-3">
              {subImages?.length ? (
                <div className="w-full">
                  <PreviewImages
                    remaoveFromSubImages={remaoveFromSubImages}
                    files={subImages}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <label
              className={`mt-1 bg-main text-white rounded-md w-fit lg:w-1/4 font-medium cursor-pointer py-2 px-4 flex items-center gap-3`}
              htmlFor="subImages"
            >
              {subImages?.length ? " Upload more file" : " Upload a file"}{" "}
              <BsCloudUpload />
            </label>
          </div>
          {/* <label
            className={`mt-1 ${
              formik.errors.subImages && formik.touched.subImages ? "" : "mb-3"
            } bg-white border-2 focus:border-main font-medium cursor-pointer pt-2 pb-10 px-4 flex items-center gap-3 w-full `}
            htmlFor="subImages"
          >
            attach a file <BsCloudUpload />
            {formik.values?.subImages?.length ? (
              <div className="">
                <PreviewImages files={formik.values.subImages} />
              </div>
            ) : (
              ""
            )}
          </label> */}

          {subImagesErr ? (
            <div className=" mb-3 rounded-md text-red-400 text-lg font-medium px-3 w-full">
              {subImagesErr}
            </div>
          ) : (
            ""
          )}
        </div>

        {err ? (
          <div className="col-span-full">
            <div
              className={`${
                formik.isValid ? "" : "mb-2"
              } rounded-md text-red-400 text-lg font-medium px-3 w-full`}
            >
              {err}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="col-span-full">
          {isLoading ? (
            <button
              disabled
              className="py-2 px-4 outline-none bg-main text-white rounded-md"
            >
              <PulseLoader
                color="#fff"
                cssOverride={{}}
                loading
                margin={2}
                size={7}
                speedMultiplier={1}
              />
            </button>
          ) : (
            <button
              onClick={() => handleSubmit()}
              type="submit"
              className="py-2 px-4 outline-none bg-main text-white rounded-md"
            >
              Create Product
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default AddProduct;
