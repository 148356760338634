import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Context/UserContext";
import CategoryData from "./CategoryData/CategoryData";
import axios from "axios";
import CategoryLoading from "./CategoryLoading/CategoryLoading";
import Pagination from "../../../utils/Pagination";
import { MdOutlineSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
function Category() {
  const { baseUrl, token, setToken, setUserData } = useContext(UserContext);
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [openCategory, setOpenCategory] = useState(null); // Track the open category
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryLimits, setCategoryLimits] = useState(5);
  const [category, setCategory] = useState(null);
  const [index, setIndex] = useState(null);
  const navigate = useNavigate();
  const getAllCategories = async () => {
    setCategoriesLoading(true);
    const data = await axios
      .get(`${baseUrl}/category/categoriesForAdmin?sort=-createdAt`, {
        headers: { authorization: `Hamada__${token}` },
      })
      .catch((err) => {
        if (!err.response) {
          toast.error("Check the internet and reload the site");
        } else if (
          err?.response?.data?.errMass == "TokenExpiredError: jwt expired" ||
          err?.response?.data?.errMass == "Your account is blocked"
        ) {
          localStorage.clear();
          setToken(null);
          setUserData(null);
          navigate("/login");
        }
      });
    if (data?.data?.categories) {
      setCategories(data?.data?.categories);
      setCategoriesLoading(false);
    }
  };

  const handleCategoryClick = (categoryId) => {
    setOpenCategory((prev) => (prev === categoryId ? null : categoryId));
  };

  if ((index || index == 0) && category?._id) {
    console.log(category);

    if (index !== -1) {
      categories[index] = category;
    }
    setCategory(null);
    setIndex(null);
  }

  const filteredCategories =
    categories?.length &&
    categories.filter((category) =>
      category.enName.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const lastCategoryIndex = currentPage * categoryLimits;
  const firstCategoryIndex = lastCategoryIndex - categoryLimits;

  const currentCategories = searchQuery?.length
    ? filteredCategories.slice(firstCategoryIndex, lastCategoryIndex) || []
    : categories?.length
    ? categories.slice(firstCategoryIndex, lastCategoryIndex)
    : categories;

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <>
      <div className="w-full p-5">
        <label
          htmlFor="searchProduct"
          className="flex items-center gap-2 rounded-lg overflow-hidden px-5 py-2 border-2 mb-4 focus:border-main bg-white"
        >
          <MdOutlineSearch className="text-2xl lg:text-4xl" />
          {categoriesLoading ? (
            <input
              id="searchProduct"
              type="text"
              className="w-full p-2   outline-none"
              placeholder="Search for a friend..."
            />
          ) : (
            <input
              id="searchProduct"
              type="text"
              className="w-full p-2   outline-none"
              placeholder="Search for a friend..."
              value={categoriesLoading ? "" : searchQuery}
              onChange={
                categoriesLoading
                  ? ""
                  : (e) => {
                      setSearchQuery(e.target.value);
                      setCurrentPage(1);
                    }
              }
            />
          )}
        </label>
        <div className="w-full pb-2 overflow-x-auto">
          <table className="w-full bg-white rounded-md">
            <thead>
              <tr className="flex items-center w-full rounded-t-md">
                <th className=" pr-3 lg:pr-5 w-[200px] min-w-[20%] xl:w-[20%]">
                  <span className="block w-full whitespace-nowrap  px-5 py-7 border-b-2">
                    Name in English
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[200px] min-w-[20%] xl:w-[20%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    Image
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[200px] min-w-[20%] xl:w-[20%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2">
                    SubCategories
                  </span>
                </th>
                <th className="px-3 lg:px-5 w-[200px] min-w-[20%] xl:w-[20%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 border-b-2 ">
                    Status
                  </span>
                </th>
                <th className="pl-3 lg:pl-5 w-[200px] min-w-[20%] xl:w-[20%]">
                  <span className="w-full block whitespace-nowrap px-5 py-7 bg-profileColor border-b-2">
                    Action
                  </span>
                </th>
              </tr>
            </thead>

            {categoriesLoading ? (
              <tbody className=" w-full">
                <CategoryLoading />
              </tbody>
            ) : currentCategories?.length ? (
              currentCategories.length == 5 ? (
                <tbody className=" w-full">
                  {currentCategories.map((category, index) => (
                    <CategoryData
                      category={category}
                      length={currentCategories.length}
                      index={index}
                      indexOfCategory={currentCategories.indexOf(category)}
                      openCategory={openCategory}
                      onCategoryClick={handleCategoryClick}
                      setCategory={setCategory}
                      setIndex={setIndex}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              ) : (searchQuery?.length
                  ? filteredCategories.length || 0
                  : categories.length) > 5 ? (
                <tbody className=" w-full">
                  {currentCategories.map((category, index) => (
                    <CategoryData
                      category={category}
                      length={currentCategories.length}
                      index={index}
                      indexOfCategory={currentCategories.indexOf(category)}
                      openCategory={openCategory}
                      onCategoryClick={handleCategoryClick}
                      setCategory={setCategory}
                      setIndex={setIndex}
                      isNotCompleted={true}
                    />
                  ))}

                  {Array(5 - currentCategories.length)
                    .fill(0)
                    .map((item, index) => {
                      return (
                        <tr className={`flex items-center w-full`}>
                          <td
                            className={`pr-3 lg:pr-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.enName}
                                className={`overflow-hidden text-ellipsis whitespace-nowrap block px-5`}
                              >
                                {/* {product.enName} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                // title={product.stock}
                                className={` overflow-hidden text-ellipsis whitespace-nowrap block px-5 `}
                              >
                                {/* {product.stock} */}
                              </span>
                            </div>
                          </td>
                          <td
                            className={`px-3 lg:px-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <div
                              className={`flex items-center justify-center  h-20 `}
                            >
                              <span
                                className={`overflow-hidden text-ellipsis whitespace-nowrap px-5 block  `}
                              ></span>
                            </div>
                          </td>
                          <td
                            className={`pl-3 lg:pl-5 w-[250px] min-w-[25%] xl:max-w-[25%]`}
                          >
                            <span
                              className={`w-full flex items-center gap-3 justify-center  overflow-hidden px-5 h-20  `}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              ) : (
                <tbody className=" w-full">
                  {currentCategories.map((category, index) => (
                    <CategoryData
                      category={category}
                      length={currentCategories.length}
                      index={index}
                      indexOfCategory={currentCategories.indexOf(category)}
                      openCategory={openCategory}
                      onCategoryClick={handleCategoryClick}
                      setCategory={setCategory}
                      setIndex={setIndex}
                      isNotCompleted={false}
                    />
                  ))}
                </tbody>
              )
            ) : (
              <tbody className=" w-full">
                <div className="size-full h-[50vh] flex items-center justify-center">
                  <p className="p-5 text-lg font-medium bg-white rounded-lg">
                    There is no Category
                  </p>
                </div>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="pb-5">
        {(searchQuery?.length
          ? filteredCategories.length || 0
          : categories.length) > 5 && (
          <Pagination
            currentPage={currentPage}
            totalRecords={
              searchQuery?.length
                ? filteredCategories.length || 0
                : categories.length
            }
            recordsLimits={categoryLimits}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
}

export default Category;
